import React from 'react';
import Services from '../../api/service'
import { Link } from "react-router-dom";

const ServiceSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    

    return (
        <section className="service_section section_space xb-hidden pb-0 mt-5">
            <div className="container">
                <div className="heading_block text-center">
                    {/* <div className="heading_focus_text">
                        Our
                        <span className="badge bg-secondary text-white">Specialize</span>
                    </div> */}
                    <h2 className="heading_text mb-0">
                    Kodo Program Highlights
                    </h2>
                </div>

                <div className="row d-flex g-3">
                    {Services.slice(0, 5).map((service, srv) => (
                        <div className={`${service.col} mt-30`} key={srv}>
                            {service.title ?
                                <div className="service_block">
                                    <div className="service_image">
                                        <img src={service.sImg} alt="IT Management Services" className='kodo-img'/>
                                    </div>
                                    <div className="service_content">
                                        <h3 className="service_title">
                                            <a>{service.title}</a>
                                            {/* <Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link> */}
                                        </h3>

                                        <div className="links_wrapper">
                                            <ul className="category_btns_group unordered_list">
                                                <li className='category_btns_blog'>
                                                    <a> {service.thumb1}</a>
                                                    {/* <Link onClick={ClickHandler} to={`/{service.thumb2}/${service.slug}`}>{service.thumb1}</Link> */}
                                                </li>
                                                <li>
                                                   <a>{service.thumb2}</a> 
                                                    {/* <Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb2}</Link> */}
                                                </li>
                                            </ul>
                                            <i className="fa-regular fa-arrow-up-right icon_block"></i>
                                            {/* <Link onClick={ClickHandler} to={`/service-single/${service.slug}`} className="icon_block">
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>
                    ))}
                </div>

                <div className="btns_group pb-0 mb-2">
                    <Link onClick={ClickHandler} className="btn" to="/about" style={{backgroundImage: "linear-gradient(to right, #3893FF, #7CACFC, #5086F8)"}}>
                        <span className="btn_label" data-text="About XNovaQ" >About XNovaQ</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;