// images
import blogImg1 from "../images/blog/blog_post_image_1.png";
import blogImg2 from "../images/blog/blog_post_image_2.png";
import blogImg3 from "../images/blog/blog_post_image_3.png";
import blogImg4 from "../images/blog/blog_post_image_12.webp";
import blogImg5 from "../images/blog/blog_post_image_13.webp";
import blogImg6 from "../images/blog/blog_post_image_14.webp";
import blogImg7 from "../images/blog/blog_post_image_15.svg";
import blogImg8 from "../images/blog/blog_post_image_16.svg";
import blogsImg1 from "../images/blog/blog_post_image_1.png";
import blogsImg2 from "../images/blog/blog_post_image_2.png";
import blogsImg3 from "../images/blog/blog_post_image_3.png";
import blogsImg4 from "../images/blog/blog_post_image_1.png";
import Img1 from "../images/blog/Frame1.webp";
import Img2 from "../images/blog/Frame2.webp";
import Img3 from "../images/blog/Frame3.webp";
import Img4 from "../images/blog/Frame4.webp";


const blogs = [
    {
        id: '1',
        title: 'The Tech World is Evolving Rapidly: Are You Keeping Up?.',
        // slug:'Leveraging-Analytics-for-Business-Growth-Modernizing',
        screens: blogImg1,
        bSingle: blogsImg1,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'farugia',
        authorTitle:'Sineor Consultant',
        create_at: '13/09/2024',
        comment:'35',
        thumb:'Tech',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: '6 Must-Have Tech Skills to Stay Relevant in 2024...',
        slug:'How-Emerging-Technologies-are-Shaping-the-Future',
        screens: blogImg2,
        bSingle: blogsImg2,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'Andrew',
        authorTitle:'Creative Director',
        create_at: '12/09/2024',
        comment:'80',
        thumb:'Skill Set',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'From Zero to Hero in 12 Months: Your IT Career Transformation...',
        slug: 'Creating-Engaging-Digital-for-Your-Audience-Software',
        screens: blogImg3,
        bSingle: blogsImg3,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'Kurtz',
        authorTitle:'Art Director',
        create_at: '11/09/2024',
        comment:'95',
        thumb:'Tech',
        blClass:'format-video',
    },
    {
        id: '4',
        title: 'How Our Software Solutions Drive Insights.',
        // slug: 'How-Our-Software-Solutions-Drive-Insights.',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: 'Student visa consulting agencies are equipped with professionals who specialize in the intricacies of visa applications.',
        author: 'Alex',
        authorTitle:'Art Director',
        create_at: '18 / 08 / 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '5',
        title: 'Exploring Emerging Trends in Software Development.',
        // slug: 'Exploring-Emerging-Trends-in-Software-Development.',
        screens: blogImg5,
        bSingle: blogsImg4,
        description: 'The realm of visa applications is a and ever-changing landscape...',
        author: 'Aliza',
        authorTitle:'Art Director',
        create_at: '25 / 08 / 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '6',
        title: 'How Software Integration Can Improve Workflow.',
        slug: 'How-Software-Integration-Can-Improve-Workflow.',
        screens: blogImg6,
        bSingle: blogsImg4,
        description: 'Embarking on a journey to study abroad is an aspiration many students..',
        author: 'Tika',
        authorTitle:'Art Director',
        create_at: '17 / 08 / 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '7',
        title: 'In-Demand IT Course Curriculum',
        slug: '5-ways-to-relieve-stress-during-the-holidays',
        screens: blogImg7,
        bSingle: blogsImg4,
        description: 'Embarking on a journey to study abroad is an aspiration many students..',
        author: 'Riley Steinmetz',
        authorTitle:'Art Director',
        create_at: 'April 20, 2024',
        comment:'95',
        thumb:'Live Well',
    },
    {
        id: '8',
        title: 'Designed To Make You An IT Expert',
        slug: 'Baby-flat-head-pillow-why-its-important',
        screens: blogImg8,
        bSingle: blogsImg4,
        description: 'Embarking on a journey to study abroad is an aspiration many students..',
        author: 'Ken William',
        authorTitle:'Art Director',
        create_at: 'April 24, 2024',
        comment:'95',
        thumb:'Get Well',
    },
    {
        id: '9',
        title: 'Direct Hire Staffing',
        slug: 'service-XnovaQ',
        screens: Img4,
        bSingle: blogsImg4,
        // description: 'Embarking on a journey to study abroad is an aspiration many students..',
        // author: 'Riley Steinmetz',
        // authorTitle:'Art Director',
        // create_at: 'April 20, 2024',
        // comment:'95',
        // thumb:'Live Well',
    },
    {
        id: '10',
        title: 'Contingent Staffing',
        slug: 'Contingent-Staffing',
        screens: Img3,
        bSingle: blogsImg4,
        // description: 'Embarking on a journey to study abroad is an aspiration many students..',
        // author: 'Ken William',
        // authorTitle:'Art Director',
        // create_at: 'April 24, 2024',
        // comment:'95',
    },
    {
        id: '11',
        title: 'Payroll Transfer',
        slug: 'payroll-transfer',
        screens: Img2,
        bSingle: blogsImg4,
        // description: 'Embarking on a journey to study abroad is an aspiration many students..',
        // author: 'Riley Steinmetz',
        // authorTitle:'Art Director',
        // create_at: 'April 20, 2024',
        // comment:'95',
    },
    {
        id: '12',
        title: 'Recruitment Process Outsourcing',
        slug: 'Recruitment-Process',
        screens: Img1,
        bSingle: blogsImg4,
        // description: 'Embarking on a journey to study abroad is an aspiration many students..',
        // author: 'Ken William',
        // authorTitle:'Art Director',
        // create_at: 'April 24, 2024',
        // comment:'95',
    },
];
export default blogs;