import timg1 from '../images/team/Hariharan.webp'
import timg2 from '../images/team/Grace.webp'

const Teams = [
   {
      Id: '1',
      tImg: timg1,
      name: 'Hariharan Jayasingh',
      // slug: 'Atticus-Sterling',
      title: 'Managing Director',
      experience : '23 Years',
      email: 'hariharan.j@xnovaq.com'
   },
   {
      Id: '2',
      tImg: timg2,
      name: 'R. Grace Lourds Beaulah',
      // slug: 'Orion-Jasper',
      title: 'Director - Talent Transformation Team',
      experience : '17 Years',
      email: 'gracebeaulah@xnovaq.com'
   }
]

export default Teams;