import React from 'react';
import PartnerSection from '../PartnerSection';
import CountUp from 'react-countup';
import sIcon1 from '../../images/icons/online-learning 2.svg'
import sIcon2 from '../../images/icons/trofy1.svg'
import sIcon3 from '../../images/icons/teamwork 2.svg'
import sIcon4 from '../../images/icons/carrierMsg.svg'
import Bg from '../../images/shapes/bg_pattern_1.svg'
import fimg from '../../images/about/about_image_1.webp'

const FunFact = [
    {
        title: '6',
        subTitle: 'Years In The Industry',
        symbol: '+',
        icon: sIcon1,
    },
    {
        title: '200',
        subTitle: 'Successful Deployments',
        symbol: '+',
        icon: sIcon2,
    },
    {
        title: '330',
        subTitle: 'Team Size',
        symbol: '+',
        icon: sIcon3,
    },
    {
        title: '100',
        subTitle: 'Guaranteed Placement',
        symbol: '%',
        icon: sIcon4,
    },


]


const FeaturesSection = (props) => {

    return (
        <section className="client_logo_section section_space]" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="mb-5 pt-0">
                    <div className="heading_block text-center">
                        <div className="heading_focus_text mb-0 mt-5">
                            What you will
                            <span className="badge bg-secondary text-white">Master</span>
                        </div>
                    </div>
                    <PartnerSection />
                </div>

                <div className="row funfact_wrapper">
                    <div className="col-md-7 col-lg-7 col-xl-8">
                        <div className="row d-flex g-3">
                            {FunFact.map((funfact, fitem) => (
                                <div className="col-md-6" key={fitem}>
                                    <div className="funfact_block h-100">
                                        <div className="funfact_icon">
                                            <img src={funfact.icon} alt="Techco - SVG Icon Head"/>
                                        </div>
                                        <div className="funfact_content">
                                            <div className="counter_value">
                                                <span className="odometer" data-count="25"><CountUp end={funfact.title} enableScrollSpy /></span>
                                                <span>{funfact.symbol}</span>
                                            </div>
                                            <h3 className="funfact_title mb-0">{funfact.subTitle}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="col-md-5 col-lg-5 col-xl-4">
                        <div className="our_world_employees">
                            <div className="image_wrap">
                                <img src={fimg} alt="IT Jobs At Coimbatore" className='kodo-img'/>
                            </div>
                            <div className="content_wrap">
                                <h3 className="title_text mb-0">
                                    {/* <b className="d-block">12000+</b> employees in 30 countries in Europe */}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesSection;