import React from "react";
import sIcon1 from "../../images/icons/icon_clock.svg";
import sIcon2 from "../../images/icons/icon_dart_board_2.svg";
import sIcon3 from "../../images/icons/icon_target.svg";
import timg1 from '../../images/team/hari.png'

const Policy = [
  {
    title: "Our History",
    subTitle:
      "Since 2019, XNovaQ has been at the forefront of technological advancements, helping businesses stay ahead of the curve.",
    icon: sIcon1,
  },
  {
    title: "Our Mission",
    subTitle:
      "To equip individuals with the in-demand global IT skills they need to contribute to the growth of the tech industry.",
    icon: sIcon2,
  },
  {
    title: "Our Vision",
    subTitle:
      "To be a global leader in tech education and talent development, shaping the future of the IT industry.",
    icon: sIcon3,
  },
];

const PolicySection = (props) => {
  return (
    <section className="policy_section bg-light">
      <div className="container">
        <div className="row">
          {Policy.map((policy, pitem) => (
            <div className="col-md-4" key={pitem}>
              <div className="iconbox_block">
                <div className="iconbox_icon">
                  <img src={policy.icon} alt="Dollar SVG Icon" />
                </div>
                <div className="iconbox_content">
                  <h3 className="iconbox_title">{policy.title}</h3>
                  <p className="mb-0">{policy.subTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    
    </section>
  );
};

export default PolicySection;
