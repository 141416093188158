import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Zod schema for form validation
const schema = z.object({
  full_name: z.string().nonempty('Full name is required').regex(/^[a-zA-Z\s]*$/, 'Only alphabets are allowed'),
  email: z.string().email('Invalid email address').nonempty('Email is required'),
  phone: z.string().nonempty('Phone number must be at least 10 characters').regex(/^\d+$/, 'Phone number must be numeric'),
  message: z.string().nonempty('Message is required'),
  companyName: z.string().nonempty('Company name is required').regex(/^[a-zA-Z\s]*$/, 'Only alphabets are allowed'),
  // Uncomment if needed for companySize and jobTitle
  // companySize: z.number().min(1, 'Company size is required'),
  // jobTitle: z.string().min(1, 'Job title is required'),
});

export default function AppointmentForm() {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://xnovaq.com/xnova-register/xnovaq-register-api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.status === 'success') {
        setResponseMessage(result.message);
        navigate('/thanks');
      } else {
        setResponseMessage(Object.values(result.message).join(', '));
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred while registering.');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row">
      <div className="col-md-6 col-lg-6">
        <label className="input_title cs_input_label cs_heading_color">Full Name</label>
        <Controller
          name="full_name"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="cs_form_field"
              placeholder="Enter Your Name"
            />
          )}
        />
        {errors.full_name && <span className="errorMessage">{errors.full_name.message}</span>}
      </div>

      <div className="col-md-6 col-lg-6">
        <label className="input_title cs_input_label cs_heading_color">Email Address</label>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="cs_form_field"
              placeholder="davidjohn@xnq.com"
            />
          )}
        />
        {errors.email && <span className="errorMessage">{errors.email.message}</span>}
      </div>

      <div className="col-md-6 col-lg-6">
        <label className="input_title cs_input_label cs_heading_color">Phone Number</label>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="cs_form_field"
              maxLength={10}
              placeholder="Enter your Phone Number"
              onKeyPress={(e) => {
                numberValidation(e);
              }}
            />
          )}
        />
        {errors.phone && <span className="errorMessage">{errors.phone.message}</span>}
      </div>

      <div className="col-md-6 col-lg-6">
        <label className="input_title cs_input_label cs_heading_color">Company Name</label>
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="cs_form_field"
              placeholder="XNovaQ"
            />
          )}
        />
        {errors.companyName && <span className="errorMessage">{errors.companyName.message}</span>}
      </div>

      <div className="col-lg-12">
        <label className="input_title cs_input_label cs_heading_color" htmlFor="input_textarea">Message</label>
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <textarea
              {...field}
              className="cs_form_field"
              placeholder="How can we help you?"
              rows={3}
            />
          )}
        />
        {errors.message && <span className="errorMessage">{errors.message.message}</span>}
      </div>

      <div className="col-lg-12">
        <button type="submit" className="cs_btn cs_style_1">
          <span>Submit</span>
        </button>
      </div>
    </form>
  );
}
