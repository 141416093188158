import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function DepartmentCarousel({ data }) {
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, ...props }) => {
    // console.log('Current Slide:', currentSlide); // Debugging
    return (
      <div
        {...props} // Ensure all props are passed
        className={
          'cs_slider_prev cs_center' +
          (currentSlide === 0 ? ' slick-disabled' : '')
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="blue"
          viewBox="0 0 24 24"
        >
          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
        </svg>
      </div>
    );
  };
  
  
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'cs_slider_next cs_center' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="blue"
        viewBox="0 0 24 24"
      >
        <path d="M8.59 16.59L13.17 12l-4.58-4.59L10 6l6 6-6 6z" />
      </svg>
    </div>
  );
  
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 6,
    swipeToSlide: true,
    pauseOnHover: false,
  pauseOnFocus: false,
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  
  return (
    <>
      <Slider
        {...settings}
        className="cs_gap_20 cs_cs_slider_navigation_1 cs_department_carousel"
      >
        {data?.map((item, index) => (
          <div key={index}>
            <Link
              to={item?.href}
              className="cs_department cs_shadow_1 cs_radius_20 cs_white_bg"
            >
              <>
                <img src={item?.iconUrl} alt="Icon" />
                <p className="cs_department_title cs_medium cs_heading_color cs_fs_20 mb-0">
                  {item?.title}
                </p>
              </>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
}
