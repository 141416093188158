import React from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const App = () => {
  return (
    <div className="App" id='scrool'>
        <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MEPV65S4Y7"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-MEPV65S4Y7');
          `}
        </script>
      </Helmet>
      <AllRoute />
      <ToastContainer />
    </div>
  );
}

export default App;