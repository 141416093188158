import React from 'react'
import blogs from '../../../api/blogs'
import Bg from '../../../images/backgrounds/bg_image_4.webp'
import wImg from '../../../images/about/Frame1.webp'
import sIcon1 from '../../../images/icons/telescope.svg'
import sIcon2 from '../../../images/icons/dollar.svg'
import sIcon3 from '../../../images/icons/global-monitor_svgrepo.com.svg'
import sIcon4 from '../../../images/icons/icon_monitor.svg'
import ServiceSection from '../../../components/software-company-components/ServiceSection/ServiceSection'
import TechnologySection from '../../../components/software-company-components/TechnologySection/TechnologySection'
import icon1 from '../../../images/icons/icon_code.svg'
import icon2 from '../../../images/icons/icon_programming_tree.svg'
import icon3 from '../../../images/icons/icon_monitor_2.svg'
import icon4 from '../../../images/icons/icon_phone.svg'
import icon5 from '../../../images/icons/icon_bug.svg'
import icon6 from '../../../images/icons/icon_programming.svg'
import Bg1 from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_5.svg'
import shape2 from '../../../images/shapes/shape_line_6.svg'
import shape3 from '../../../images/shapes/shape_space_1.svg'
import shape4 from '../../../images/shapes/shape_angle_1.webp'
import shape5 from '../../../images/shapes/shape_angle_2.webp'
import Header from '../../header/Header'
import Footer from '../../../components/footer/Footer';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import FaqSection from '../../software-company-components/FaqSection/FaqSection';
import ContactSection from '../../software-company-components/ContactSection/index';
import { Helmet } from 'react-helmet';
const BusinessBlogSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const Policy = [
        {
            title: 'Industry Recognized Certification',
            icon: sIcon1,
        },
        {
            title: '100% offline and Self-paced learning',
            icon: sIcon2,
        },
        {
            title: 'Access to Global Projects',
            icon: sIcon3,
        },
        {
            title: 'Industry Ready From Day One',
            icon: sIcon4,
        }

    ]

    const faqData = [
        {
            question: "Q. What specific IT skills are covered in the EEP curriculum?",
            answer: "The EEP curriculum covers a wide range of in-demand IT skills, including programming languages (e.g., Python, Java, C++), Springboot, and soft skills. The specific skills covered may vary depending on the program track chosen."
        },
        {
            question: "Q. Is prior knowledge of IT required to join the EEP program?",
            answer: "While prior knowledge of IT can be beneficial, it is not a strict requirement. The program is designed to cater to individuals with varying levels of technical expertise."
        },
        {
            question: "Q. How is the curriculum structured?",
            answer: "The EEP curriculum is carefully designed to provide a comprehensive and practical learning experience. It combines theoretical knowledge with hands-on projects and real-world applications. The program may be divided into modules or tracks, allowing students to specialize in their areas of interest."
        },
        {
            question: "Q. How often are the curriculum and content updated to reflect industry trends?",
            answer: "XNovaQ regularly reviews and updates the EEP curriculum to ensure it remains aligned with the latest industry trends and technologies. This ensures that our students are equipped with the most relevant skills for their careers."
        },
        {
            question: "Q. Are there any opportunities for specialization within the EEP program?",
            answer: "Yes, the EEP program may offer opportunities for specialization in specific areas of IT, such as Service Now, SAP, and Salesforce. These specialized tracks allow students to delve deeper into their chosen field and acquire advanced skills after the initial 12 months."
        },
        {
            question: "Q. How long does it take to complete the EEP program?",
            answer: "The duration of the EEP program may vary depending on the specific track or specialization chosen. However, the initial program is for 12 months, and specialization programs after that last from 6 months to 12 months."
        }
    ];


    const Services = [
        {
            Id: '1',
            sImg: icon1,
            title: 'JAVA',
            slug: 'Custom Software Development',
            features: ['Data Structures and Algorithms', 'OOPS AND JVM Architecture', 'File Handling Multithreading and Collections', 'SQL Queries and JDBC Connection']
        },
        {
            Id: '2',
            sImg: icon2,
            title: 'HTML',
            slug: 'Audit-&-IT-Consulting-Services',
            features: ['HTML5 Templates and Forms', 'CSS3 and Layout Designs', 'Java Scripts', 'JQuery']
        },
        {
            Id: '3',
            sImg: icon3,
            title: 'Spring Boot',
            slug: 'Web-Application-Design-and-Development',
            features: ['Spring Framework and Architecture', 'Spring Security', 'Microservices', 'Kafka']
        },
        {
            Id: '4',
            sImg: icon4,
            title: 'React JS',
            slug: 'Mobile-App-Design-and-Development',
            features: ['React Components', 'States and Props', 'Life Cycle Methods and Hooks', 'Routing, Context API, and Redux (State Management)']
        },
        {
            Id: '5',
            sImg: icon5,
            title: 'Python',
            slug: 'Best-UI/UX-Design-Services',
            features: ['Functions, Modules', 'Decorators and Generators', 'Concurrency and Parallelism', 'InterfaceGenius UI/UX Design']
        },
        {
            Id: '6',
            sImg: icon6,
            title: 'GKE',
            slug: 'Maintenance-and-Customer-Support',
            features: ['Cluster Creation and Management', 'Pod Scheduling and Resource Management', 'Service Exposure and Load Balancing', 'Monitoring, Logging, and Autoscaling']
        }
    ]

    const Services1 = [
        {
            Id: '1',
            sImg: icon1,
            title: 'Communication Skills',
            slug: 'Custom Software Development',
            features: ['Introduction to Effective Communication', 'Introduction to Impromptu Speaking', 'Communication in Teams', 'Presentation Skills']
        },
        {
            Id: '2',
            sImg: icon2,
            title: 'Emotional Intelligence',
            slug: 'Audit-&-IT-Consulting-Services',
            features: ['Understanding Emotional Intelligence', 'Managing Emotions', 'Empathy and Social Skills', 'Applying Emotional Intelligence']
        },
        {
            Id: '3',
            sImg: icon3,
            title: 'Leadership and Teamwork',
            slug: 'Web-Application-Design-and-Development',
            features: ['Fundamentals of Leadership', 'Team Dynamics and Collaboration', 'Delegation and Motivation', 'Conflict Resolution and Negotiation']
        }
    ]


    const ShapeImages = [
        {
            Id: '1',
            sImg: shape1,
        },
        {

            Id: '1',
            sImg: shape2,
        },
        {
            Id: '1',
            sImg: shape3,
        },
        {
            Id: '1',
            sImg: shape4,
        },
        {
            Id: '1',
            sImg: shape5,
        },
    ]

    return (
        <>
            <Header />
            <Helmet>
                <title>IT Course and Program in Coimbatore | XNovaQ</title>
                <meta
                    name="description"
                    content="Transform your future with XNovaQ. Top IT courses in Coimbatore. Job-ready skills, expert guidance."
                />
            </Helmet>
            <section className="blog_section_2 section_space mt-5" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="heading_block text-center" style={{ marginTop: '100px' }}>
                                <h2 className="heading_text">
                                    Explore the Kodo Program
                                </h2>
                                <p className="heading_description text-dark mb-0">
                                    Take the Next Step: Enroll in Our Kodo Program
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {blogs.slice(6, 8).map((blog, Bitem) => (
                            <div className="col-lg-6" key={Bitem}>
                                <div className="blog_post_block_2">
                                    <div className="blog_post_image">
                                        <a >
                                            <img src={blog.screens} alt="Best IT Course in India" />
                                        </a>
                                    </div>
                                    <div className="blog_post_content">
                                        <ul className="category_list unordered_list">
                                            <li><a href="portfolio.html">{blog.thumb}</a></li>
                                        </ul>
                                        <h3 className="post_title">
                                            <a>
                                                {blog.title}
                                            </a>
                                        </h3>
                                        {/* <ul className="post_meta unordered_list">
                <li>
                    <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                        By <b>{blog.author}</b>
                    </Link>
                </li>
                <li>
                    <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                        {blog.create_at}
                    </Link>
                </li>
            </ul> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="service_section section_space bg-light">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-md-6">
                            <div className="image_wrap">
                                <img src={wImg} alt="IT Courses in Coimbatore" className='kodo-img'/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ps-lg-5">
                                <div className="heading_block">
                                    <div className="heading_focus_text">
                                        <span className="badge bg-secondary text-white">Why Us</span>
                                        Better
                                    </div>
                                    <h2 className="heading_text mb-0">
                                        Key Features at XnovaQ
                                    </h2>
                                </div>
                                <ul className="service_facilities_group unordered_list ">
                                    {Policy.map((policy, pitem) => (
                                        <li key={pitem}>
                                            <strong className="iconbox_block layout_icon_left flex--wrap flex-lg-nowrap">
                                                <span className="iconbox_icon">
                                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                                </span>
                                                <span className="iconbox_content">
                                                    <strong className="iconbox_title mb-0">{policy.title}</strong>
                                                </span>
                                            </strong>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServiceSection
                title=""
                subtitle="Overview Of Technical Skills"
                services={Services}
                bgImage={Bg1}
                shapes={ShapeImages} />
            <ServiceSection
                title=""
                subtitle="Overview Of Non-Technical Skills"
                services={Services1}
                bgImage={Bg1}
                shapes={ShapeImages} />
            <TechnologySection />
            <FaqSection
                title="F.A.Q."
                subtitle="Need a Support?"
                faqs={faqData}
            />
            <ContactSection />
            <Footer />
            <Scrollbar />
        </>
    )
}

export default BusinessBlogSection;