import React, { useState } from 'react';

const EmpoweringSuccess = ({ title, image, services }) => {
    const [isOpen, setOpen] = useState(false);
    
    return (
        <section className="empowering_success_section section_space bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="heading_block text-center">
                            <h2 className="heading_text mb-0 mt-5">{title}</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="image_wrap position-relative">
                            <img className="rounded kodo-img" src={image} alt="Empowering Success" />
                            {/* Uncomment the button below if you want to use the video feature */}
                            {/* <button onClick={() => setOpen(true)} className="video_btn ripple_effect">
                                <span className="btn_icon">
                                    <i className="fa-solid fa-play"></i>
                                </span>
                            </button> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ul className="empowering_services unordered_list_block ps-lg-5">
                            {services.map((service, index) => (
                                <li key={index}>
                                    <div className="iconbox_block layout_icon_left">
                                        <div className="iconbox_icon">
                                            <img src={service.icon} alt={service.altText} />
                                        </div>
                                        <div className="iconbox_content">
                                            <h3 className="iconbox_title">{service.title}</h3>
                                            <p className="mb-0">{service.description}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {/* Uncomment the line below if you want to use the ModalVideo component */}
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} /> */}
        </section>
    );
};

// PropTypes for type checking
// EmpoweringSuccess.propTypes = {
//     title: PropTypes.string.isRequired,
//     image: PropTypes.string.isRequired,
//     services: PropTypes.arrayOf(
//         PropTypes.shape({
//             icon: PropTypes.string.isRequired,
//             title: PropTypes.string.isRequired,
//             description: PropTypes.string.isRequired,
//             altText: PropTypes.string,
//         })
//     ).isRequired,
// };

export default EmpoweringSuccess;
