import React from 'react'
import BannerSectionStyle3 from '../../components/HomePage/components/Section/BannerSection/BannerSectionStyle3'
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import AppointmentWithContactInfoSection from '../../components/HomePage/components/Section/AppointmentWithContactInfoSection';
import letstalk from '../../images/lets-talk.webp'
import Section from '../../components/HomePage/components/Section';
import bgGlobe from '../../images/backgrounds/bgGlobe.webp';


const LetsTalk = () => {
  return (
    <>
    <Header/>
    <Section bottomMd={125} bottomLg={50} bottomXl={85}>
    <BannerSectionStyle3
     bgUrl={bgGlobe}
     imgUrl={letstalk}
     title="Get in touch with our team!"
     subTitle="Contact us today to explore how XNovaQ can help you"
    //  btnText="Get Starter"
    //  btnUrl="/contact"
     />
     </Section>
     <Section bottomMd={125} bottomLg={50} bottomXl={85}>
    <AppointmentWithContactInfoSection />
    </Section>
    <Footer/>
    </>
  )
}

export default LetsTalk