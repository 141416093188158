import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero3 from '../../components/hero3/hero3';
import EmpoweringSuccess from '../../components/business-consulting-components/EmpoweringSuccess/EmpoweringSuccess';
import ConsultingService from '../../components/business-consulting-components/ConsultingService/ConsultingService';
import BusinessTestimonial from '../../components/business-consulting-components/BusinessTestimonial/BusinessTestimonial';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';
import Bg from '../../images/hero/business_consulting_hero_section_bg.jpg'
import shape1 from '../../images/hero/logo_google.svg'
import shape2 from '../../images/hero/serviceImg1.webp'
import shape3 from '../../images/hero/business_consulting_hero_image_2.webp'
import icon1 from '../../images/icons/icon_user_3.svg'
import icon2 from '../../images/icons/icon_wallet.svg'
import icon11 from '../../images/icons/icon_bulb.svg'
import icon12 from '../../images/icons/icon_user_rating.svg'
import icon3 from '../../images/icons/icon_hand_shake.svg'
import eImg from '../../images/about/Img 2.webp'
import abImg1 from '../../images/about/Img 3.webp'
import hireStaffing from '../../api/hireStaffing';


const ServiceXNovaQPage = (props) => {
  const faqData = [
    {
      question: 'What is Direct Hire Staffing?',
      answer: 'Direct hire staffing is a recruitment service where XNovaQ acts as your partner to identify, screen, and place suitable candidates for permanent positions within your organization. We handle the entire hiring process, from sourcing to onboarding, saving you time and resources.',
    },
    {
      question: "How does XNovaQ's Direct Hire Staffing process work?",
      answer: 'Our process involves:',
      listItem: [
        'Understanding your needs: We thoroughly understand your company culture, job requirements, and desired candidate profile.',
        'Sourcing and screening: We leverage our extensive network to find qualified candidates and conduct rigorous screening to ensure they meet your criteria.',
        'Candidate interviews: We facilitate interviews between you and shortlisted candidates.',
        'Reference checks and background verification: We verify candidate credentials and references to ensure their integrity.',
        'Offer extension and onboarding: We assist with offer negotiation and smooth onboarding processes.',
      ],
    },
    {
      question: 'What industries does XNovaQ specialize in for Direct Hire Staffing?',
      answer: 'XNovaQ offers direct hire staffing services across a wide range of industries, including:',
      listItem: [
        'Technology',
        'Healthcare',
        'Finance',
        'Engineering',
        'Manufacturing',
        'Retail',
        'And more',
      ],
    },
    {
      question: 'How long does the Direct Hire Staffing process typically take?',
      answer: 'The timeline for direct hire staffing can vary depending on factors like the specific role, industry, and availability of suitable candidates. However, XNovaQ strives to expedite the process while ensuring quality.',
    },
    {
      question: 'What is the cost of Direct Hire Staffing with XNovaQ?',
      answer: 'Our fees are competitive and based on a contingency model. We charge a fee upon successful placement, making it a cost-effective solution for your hiring needs.',
    },
    {
      question: 'Can I interview candidates myself?',
      answer: 'Yes, you have the opportunity to interview candidates personally to assess their fit within your organization. XNovaQ can provide guidance and support throughout the interview process.',
    },
    {
      question: 'How do you ensure candidate quality?',
      answer: 'XNovaQ employs rigorous screening processes, including background checks, reference verification, and in-depth interviews to ensure the quality of candidates we present.',
    },
    {
      question: "What if I'm not satisfied with the candidates presented?",
      answer: 'We maintain open communication and work closely with you to understand your preferences. If you\'re not satisfied with the initial candidates, we\'ll continue our search to find the perfect match.',
    },
    {
      question: 'Can you help with onboarding new hires?',
      answer: 'Yes, XNovaQ can provide support with onboarding to ensure a smooth transition for new employees. We can assist with paperwork, introductions, and any necessary training.',
    },
    {
      question: 'How does XNovaQ differentiate itself from other staffing agencies?',
      answer: 'XNovaQ stands out due to:',
      listItem: [
        'Deep industry expertise: Our recruiters have in-depth knowledge of various industries.',
        'Personalized service: We prioritize your unique needs and provide tailored solutions.',
        'Strong candidate network: We have access to a vast pool of qualified talent.',
        'Efficient processes: Our streamlined approach saves you time and resources.',
        'Commitment to quality: We prioritize candidate quality and client satisfaction.',
      ],
    },
  ];

  const reviews = {
    logo: shape1,
    rating: 5,
    count: 128,
  };

  const funFacts = [
    {
      value: 95,
      title: 'Success Rate',
      delay: '.1s',
      position: '',
      icon: icon1,
      altText: 'Success Icon',
    },
    {
      value: 47,
      title: 'Faster Time-To-Hire',
      delay: '.2s',
      position: 'middle',
      icon: icon2,
      altText: 'Hire Icon',
    },
    {
      value: 32,
      title: 'Lower Cost To Hire',
      delay: '.3s',
      position: '',
      icon: null,
      altText: '',
    },
  ];

  const servicesData = [
    {
      icon: icon11,
      title: 'Expert Talent Acquisition',
      description: 'Our team of seasoned recruiters possesses deep industry knowledge and a proven track record of sourcing and selecting the best candidates for your organization.',
      altText: 'Bulb SVG Icon',
    },
    {
      icon: icon12,
      title: 'Tailored Recruitment Solutions',
      description: 'We understand that every business has unique hiring needs. Our flexible approach ensures that our services align perfectly with your specific requirements.',
      altText: 'User Rating SVG Icon',
    },
    {
      icon: icon3,
      title: 'Streamlined Hiring Processes',
      description: 'We employ efficient and effective methodologies to accelerate the hiring process, saving you valuable time and resources.',
      altText: 'Hand Shake SVG Icon',
    },
  ];


  return (
    <Fragment>
      <div className='index_business_consulting'>
        <main className="page_content s2">
          <Header />
          <Hero3
            backgroundImage={Bg}
            title="Direct"
            title2="Hire"
            title3="Staffing"
            subtitle="Find the Perfect Talent for Your Growing Business"
            buttonLabel="Get Started Today"
            buttonLink="/contact"
            image1={shape3}
            image2={shape2}
            // reviews={reviews}
            funFacts={funFacts}
            onClickHandler={() => {
              console.log('Button clicked!');
            }}
          />
          {/* <PartnerSection /> */}
          <EmpoweringSuccess
            title="Why XNovaQ should be your Direct Staff Hiring Partner:"
            image={eImg}
            services={servicesData}
          />
          <ConsultingService
            title="XNovaQ Direct Hire Staffing Process"
            subTitle="A Step-by-Step Guide to Finding Your Perfect Talent"
            services= {hireStaffing} />
          <BusinessTestimonial
            title=" Why Partner with XNovaQ for Direct Hire Staffing"
            content1=" Partner with XNovaQ and experience the difference. Our commitment to excellence, combined with our proven track record and personalized approach, makes us your ideal partner for direct hire staffing."
            content2="Contact us today to learn more about how XNovaQ can achieve your business goals."
            img={abImg1}
          />
          <FaqSection
            title="F.A.Q."
            subtitle="Need a Support?"
            faqs={faqData}
          />
          <Footer />
          <Scrollbar />
        </main>
      </div>
    </Fragment>
  )
}

export default ServiceXNovaQPage;