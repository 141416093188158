import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const ContactForm = () => {
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState("");

  // Define the Zod schema
  const validationSchema = z.object({
    full_name: z
      .string()
      .nonempty("Full name is required")
      .regex(/^[a-zA-Z\s]*$/, "Full name must only contain letters and spaces"),
    email: z.string().nonempty("Email is required").email("Invalid email"),
    phone: z
      .string()
      .nonempty("Phone number is required")
      .regex(/^[6-9]\d{9}$/, "Phone number must be 10 digits starting with 6, 7, 8, or 9"),
    city: z
      .string()
      .nonempty("City is required")
      .regex(/^[a-zA-Z\s]*$/, "City must only contain letters and spaces"),
    highest_degree: z
      .string()
      .nonempty("Highest degree is required")
      .regex(/^[a-zA-Z\s]*$/, "Highest degree must only contain letters and spaces"),
    job_title: z
      .string()
      .nonempty("Job title is required")
      .regex(/^[a-zA-Z\s]*$/, "Job title must only contain letters and spaces"),
    message: z.string().nonempty("Message is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://xnovaq.com/xnova-register/xnovaq-register-api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      if (result.status === "success") {
        setResponseMessage(result.message);
        reset(); // Reset the form fields
        navigate("/thanks");
      } else if (result.status === "error") {
        setResponseMessage(Object.values(result.message).join(", "));
      }
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("An error occurred while registering.");
    }
  };

  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  return (
    <form className="xb-item--form contact-from" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="full_name">
              <i className="fa-regular fa-user"></i>
            </label>
            <input
              type="text"
              {...register("full_name")}
              className="form-control"
              placeholder="Your Name"
            />
            {errors.full_name && <span className="errorMessage text-danger">{errors.full_name.message}</span>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="email">
              <i className="fa-regular fa-envelope"></i>
            </label>
            <input
              type="email"
              {...register("email")}
              className="form-control"
              placeholder="Email"
            />
            {errors.email && <span className="errorMessage text-danger">{errors.email.message}</span>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="phone">
              <i className="fa-regular fa-phone-volume"></i>
            </label>
            <input
              type="text"
              {...register("phone")}
              className="form-control"
              maxLength={10}
              placeholder="Phone Number"
              onKeyPress={(e) => {
                numberValidation(e);
              }}
            />
            {errors.phone && <span className="errorMessage text-danger">{errors.phone.message}</span>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="city">
              <i className="fa-solid fa-city"></i>
            </label>
            <input
              type="text"
              {...register("city")}
              className="form-control"
              placeholder="City"
            />
            {errors.city && <span className="errorMessage text-danger">{errors.city.message}</span>}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="highest_degree">
              <i className="fa-solid fa-graduation-cap"></i>
            </label>
            <input
              type="text"
              {...register("highest_degree")}
              className="form-control"
              placeholder="Highest Degree"
            />
            {errors.highest_degree && (
              <span className="errorMessage text-danger">{errors.highest_degree.message}</span>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="input_title" htmlFor="job_title">
              <i className="fa-solid fa-briefcase"></i>
            </label>
            <input
              type="text"
              {...register("job_title")}
              className="form-control"
              placeholder="Job Title"
            />
            {errors.job_title && <span className="errorMessage text-danger">{errors.job_title.message}</span>}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <label className="input_title" htmlFor="message">
              <i className="fa-regular fa-comments"></i>
            </label>
            <textarea
              {...register("message")}
              className="form-control"
              placeholder="How can we help you?"
            ></textarea>
            {errors.message && <span className="errorMessage text-danger">{errors.message.message}</span>}
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              backgroundImage: "linear-gradient(to right, #3893FF, #7CACFC, #5086F8)",
            }}
          >
            <span className="btn_label" data-text="Send Request">
              Send Request
            </span>
            <span className="btn_icon">
              <i className="fa-solid fa-arrow-up-right"></i>
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
