import React, { useState, useEffect } from "react";
import "../../css/popup.css";
import popImage from "../../images/hero/popImage.webp";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

const Popup = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  // Validation schema using zod
  const validationSchema = z.object({
    full_name: z
      .string()
      .nonempty("Full name is required")
      .regex(/^[a-zA-Z\s]*$/, "Full name must only contain letters and spaces"),
    email: z.string().nonempty("Email is required").email("Invalid email"),
    phone: z
      .string()
      .nonempty("Phone number is required")
      .regex(/^[6-9]\d{9}$/, "Phone number must be 10 digits starting with 6, 7, 8, or 9"),
    city: z
      .string()
      .nonempty("City is required")
      .regex(/^[a-zA-Z\s]*$/, "City must only contain letters and spaces"),
  });

  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    try {
      const response = await fetch(
        "https://xnovaq.com/xnova-register/xnovaq-register-api.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();

      if (result.status === "success") {
        reset(); // Reset the form fields
        navigate("/thanks");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    if (modalElement) {
      modalElement.style.display = "block";
      modalElement.classList.add("show");
    }
  }, [showPopup]);
  
  return (
    <>
      {showPopup && (
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title primary">
                  <mark>Start Your IT Career Today!</mark>
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setShowPopup(false)}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div>
                    <p className="m-0">
                      Limited Seats: Enroll in <mark>XNovaQ's Kodo program</mark> today as a
                      <span>
                        <mark> 'Programmer Trainee'</mark>
                      </span>{" "}
                      and start earning.
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="image-container">
                      <img src={popImage} alt="Talents" className="kodo-img" />
                    </div>
                  </div>
                  <div className="popup-para col-sm-12 col-md-12 col-lg-6">
                    <div className="instant_contact_form p-2">
                      <form
                        className="xb-item--form contact-from"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="form-group">
                          <label className="input_title" htmlFor="full_name">
                            <i className="fa-regular fa-user"></i>
                          </label>
                          <input
                            type="text"
                            {...register("full_name")}
                            className="form-control"
                            placeholder="Your Name"
                          />
                          {errors.full_name && (
                            <span className="errorMessage text-danger m-0">
                              {errors.full_name.message}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="input_title" htmlFor="email">
                            <i className="fa-regular fa-envelope"></i>
                          </label>
                          <input
                            type="email"
                            {...register("email")}
                            className="form-control"
                            placeholder="Email"
                          />
                          {errors.email && (
                            <span className="errorMessage text-danger m-0">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="input_title" htmlFor="phone">
                            <i className="fa-regular fa-phone-volume"></i>
                          </label>
                          <input
                            type="text"
                            maxLength={10}
                            {...register("phone")}
                            className="form-control"
                            placeholder="Phone Number"
                            onKeyPress={(e) => {
                              numberValidation(e);
                            }}
                          />
                          {errors.phone && (
                            <span className="errorMessage text-danger m-0">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="input_title" htmlFor="city">
                            <i className="fa-solid fa-city"></i>
                          </label>
                          <input
                            type="text"
                            {...register("city")}
                            className="form-control"
                            placeholder="City"
                          />
                          {errors.city && (
                            <span className="errorMessage text-danger m-0">
                              {errors.city.message}
                            </span>
                          )}
                        </div>
                        <div className="col-12 p-0">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              backgroundImage:
                                "linear-gradient(to right, #3893FF, #7CACFC, #5086F8)",
                            }}
                          >
                            <span
                              className="btn_label"
                              data-text="Send Request"
                            >
                              Send Request
                            </span>
                            <span className="btn_icon">
                              <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
