import React, { Fragment } from 'react';
import HeroStyle2 from '../Hero/HeroStyle2';
import Header from '../../../../components/header/Header';
import Footer from '../../../../components/footer/Footer';
import Scrollbar from '../../../../components/scrollbar/scrollbar';
import Section from '../Section';
import AboutSectionStyle3 from '../Section/AboutSection/AboutSectionStyle3';
import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import WorkingProcess from '../Section/WorkingProcess';
import FaqSectionStyle2 from '../Section/FaqSection/FaqSectionStyle2';
import BrandsSection from '../Section/BrandsSection';
import { pageTitle } from '../../components/helpers/PageTitle';
import HomeV1 from '../../../../images/hero/HomeV1.webp';
import Banner1 from '../../../../images/backgrounds/globe.webp';
import bgGlobe from '../../../../images/backgrounds/bgGlobe.webp';
import Banner from '../Section/BannerSection';
import DepartmentSection from '../Section/DepartmentSection';
import AppointmentWithContactInfoSection from '../Section/AppointmentWithContactInfoSection';
import aboutimg from '../../../../images/backgrounds/aboutimg.webp';
import depImg from '../../../../images/backgrounds/department_bg.webp';
import homeImg from '../../../../images/backgrounds/homeImg1.webp';
import icon1 from '../../../../images/icons/homeIcon/bill-invoice-ui_svgrepo.com.svg';
import icon2 from '../../../../images/icons/homeIcon/corp-supplies_svgrepo.com.svg';
import icon3 from '../../../../images/icons/homeIcon/deploy_svgrepo.com.svg';
import icon4 from '../../../../images/icons/homeIcon/human-resources-search_svgrepo.com.svg';
import icon5 from '../../../../images/icons/homeIcon/multi-skills-employee_svgrepo.com.svg';
import icon6 from '../../../../images/icons/homeIcon/search-career-work-job-recruitment-employee-3_svgrepo.com.svg';
import icon7 from '../../../../images/icons/homeIcon/Airplane.png';
import icon8 from '../../../../images/icons/homeIcon/Bank.png';
import icon9 from '../../../../images/icons/homeIcon/Car.png';
import icon10 from '../../../../images/icons/homeIcon/Coding.png';
import icon11 from '../../../../images/icons/homeIcon/Fast food.png';
import icon12 from '../../../../images/icons/homeIcon/Heart beat.png';
import icon13 from '../../../../images/icons/homeIcon/It services.png';
import icon14 from '../../../../images/icons/homeIcon/Oil pump.png';
import icon15 from '../../../../images/icons/homeIcon/Shopping cart.png';
import icon16 from '../../../../images/icons/homeIcon/Technical Support.png';
import icon17 from '../../../../images/icons/homeIcon/image 335.png';
import icon18 from '../../../../images/icons/homeIcon/image 338.png';
import icon19 from '../../../../images/icons/homeIcon/image 339.png';
import icon20 from '../../../../images/icons/homeIcon/image 340.png';
import icon21 from '../../../../images/icons/homeIcon/image 342.png';
import icon22 from '../../../../images/icons/homeIcon/image 341.png';
import icon23 from '../../../../images/icons/homeIcon/calendar_white.svg';
import icon24 from '../../../../images/icons/homeIcon/talent-development.png';
import icon25 from '../../../../images/icons/homeIcon/data-line.png';
import icon26 from '../../../../images/icons/homeIcon/pipeline.png';
import icon27 from '../../../../images/icons/homeIcon/onboarding.png';
import icon28 from '../../../../images/icons/homeIcon/light-bulb-idea.png';
import DepartmentSectionStyle6 from '../Section/DepartmentSection/DepartmentSectionStyle6';
import WhyUs from '../../../../main-component/AboutUsPage/WhyUs';
import staffing from '../../../../images/xnq.png';


const HomeStyle2 = () => { 
const departmentData = [
  {
    title: 'Recruitment Process Outsourcing (RPO)',
    subTitle: 'Outsource your entire hiring process to XNovaQ.',
    iconUrl: icon6,
    href: '/Recruitment-Process',
  },
  {
    title: 'Permanent Staffing',
    subTitle: 'Find your ideal long-term employees.',
    iconUrl: icon5,
    href: '/service-XnovaQ',
  },
  {
    title: 'Contingent Staffing (C2H)',
    subTitle: 'Hire flexible talent on a contract basis.',
    iconUrl: icon2,
    href: '/Contingent-Staffing',
  },
  {
    title: 'Bulk Hiring',
    subTitle: 'Quickly scale your workforce with our bulk hiring solutions.',
    iconUrl: icon4,
    href: '',
  },
  {
    title: 'Hire, Train, Deploy (HTD)',
    subTitle: 'Hire raw talent, train them, and deploy them to your projects.',
    iconUrl: icon3,
    href: '',
  },
  {
    title: 'Payroll Transfer',
    subTitle: 'Simplify your payroll process with our seamless transfer services.',
    iconUrl: icon1,
    href: '',
  },
];
const testimonialData = [
  {
    text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
    ratingNumber: '5',
    avatarImgUrl: '/images/home_1/avatar_2.png',
    avatarName: 'PAULO HUBERT',
    avatarDesignation: 'New York, USA',
  },
  {
    text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
    ratingNumber: '4.5',
    avatarImgUrl: '/images/home_1/avatar_2.png',
    avatarName: 'PAULO HUBERT',
    avatarDesignation: 'New York, USA',
  },
  {
    text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
    ratingNumber: '5',
    avatarImgUrl: '/images/home_1/avatar_2.png',
    avatarName: 'PAULO HUBERT',
    avatarDesignation: 'New York, USA',
  },
  {
    text: 'I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.”',
    ratingNumber: '4.5',
    avatarImgUrl: '/images/home_1/avatar_2.png',
    avatarName: 'PAULO HUBERT',
    avatarDesignation: 'New York, USA',
  },
];
const brandData1 = [
  { imgUrl: icon17, imgAlt: 'Brand' },
  { imgUrl: icon18, imgAlt: 'Brand' },
  { imgUrl: icon19, imgAlt: 'Brand' },
  { imgUrl: icon20, imgAlt: 'Brand' },
  { imgUrl: icon21, imgAlt: 'Brand' },
  { imgUrl: icon22, imgAlt: 'Brand' }
];
const departmentData2 = [
  {
    title: 'Tailored Talent Solutions',
    subTitle:
      'Customized strategies for your unique business needs.',
    iconUrl: icon24,
    href: '',
  },
  {
    title: 'Data-Driven Recruitment',
    subTitle:'Advanced analytics for optimal candidate selection',  
    iconUrl: icon25,
    href: '',
  },
  {
    title: 'Proactive Talent Pipeline',
    subTitle:'Ready-to-deploy, pre-vetted candidates.',
    iconUrl: icon26,
    href: '',
  },
  {
    title: 'Seamless Onboarding',
    subTitle:'Holistic programs for smooth integration.',
    iconUrl: icon27,
    href: '',
  },
  {
    title: 'Continuous Innovation',
    subTitle:
      'Regular process improvements based on feedback.',
    iconUrl: icon28,
    href: '',
  },
];
const workingProcessData = [
  {
    title: 'Consultation',
    subTitle:'Understanding your unique staffing needs.',
    icon: icon5,
    number: '01',
  },
  {
    title: 'Customized Solutions',
    subTitle:'Tailoring our approach based on your requirements.',
    icon: icon10,
    number: '02',
  },
  {
    title: 'Talent Sourcing',
    subTitle:'Leveraging our network to find the right candidates.',
    icon: icon4,
    number: '03',
  },
  {
    title: 'Screening & Assessment',
    subTitle:'Rigorous evaluation to ensure a perfect match.',
    icon: icon2,
    number: '04',
  },
  {
    title: 'Onboarding & Support',
    subTitle:'Seamless onboarding and continued support for both clients and candidates.',
    icon: icon6,
    number: '05',
  },
];
const blogData = [
  {
    title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
    thumbUrl: 'images/home_1/post_1.jpeg',
    date: 'May 1, 2023',
    href: '/',
  },
  {
    title: 'Healthy Eating on a Budget: Tips and Strategies',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: 'May 4, 2023',
    href: '/',
  },
  {
    title: 'The Importance of Regular Cancer Screenings and Early Detection',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: 'May 1, 2023',
    href: '/',
  },
];
const faqData = [
  {
    title: 'What services does XNovaQ offer?',
    content:
      'XNovaQ offers RPO, permanent staffing, contingent staffing (C2H), bulk hiring, Hire, Train, Deploy (HTD), and payroll transfer services.',
  },
  {
    title: 'How does XNovaQ find the right candidates?',
    content:
      'We use advanced sourcing techniques, a vast network, and rigorous screening processes to identify and attract top talent.',
  },
  {
    title: 'What makes XNovaQ different from other staffing agencies?',
    content:
      'Our customized talent solutions, data-driven recruitment, proactive talent pipeline, seamless onboarding, and continuous innovation set us apart.',
  },
  {
    title: 'How quickly can XNovaQ fill a position?',
    content:
      'The timeline varies based on the role and requirements, but our efficient processes aim to fill positions as quickly as possible.',
  },
  {
    title: 'Can XNovaQ handle specialized or niche roles?',
    content:
      'Yes, we have experience in sourcing and placing candidates for specialized and niche roles across various industries.',
  },
  {
    title: 'What is the cost structure for XNovaQ’s services?',
    content:
      'Our cost structure varies by service, with permanent staffing typically involving a one-time fee of 8.33% of the candidate’s CTC.',
  },
  {
    title: 'How does XNovaQ support new hires during onboarding?',
    content:
      'We provide comprehensive onboarding support to ensure new hires integrate smoothly into your organization.',
  },
  {
    title: 'Does XNovaQ offer temporary staffing solutions?',
    content:
      'Yes, we offer contingent staffing (C2H) options for flexible, contract-to-hire arrangements.',
  },
  {
    title: 'How can I request a staffing solution from XNovaQ?',
    content:
      'You can contact us through our website or reach out directly to discuss your specific staffing needs.',
  },
  {
    title: 'What kind of companies does XNovaQ work with?',
    content:
      'We work with a wide range of companies, from startups to large enterprises, across various industries.',
  },
];

const brandData = [
  {
    imgUrl: '/images/brand_1.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_2.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_3.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_4.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_5.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_6.png',
    imgAlt: 'Brand',
  },
  {
    imgUrl: '/images/brand_7.png',
    imgAlt: 'Brand',
  },
];

const departmentData1 = [
  {
    title: 'Information Technology (IT)',
    iconUrl: icon13,
    href: '',
  },
  {
    title: 'Mechanical Engineering',
    iconUrl:  icon16,
    href: '',
  },
  {
    title: 'Automotive',
    iconUrl:  icon9,
    href: '',
  },
  {
    title: 'Aerospace',
    iconUrl:  icon7,
    href: '',
  },
  {
    title: 'Healthcare',
    iconUrl:  icon12,
    href: '',
  },
  {
    title: 'BFSI (Banking, Financial Services, and Insurance)',
    iconUrl:  icon8,
    href: '',
  }
];

const departmentData3 = [
  {
    title: 'Retail',
    iconUrl: icon15,
    href: '',
  },
  {
    title: 'Embedded Systems',
    iconUrl: icon10,
    href: '',
  }, {
    title: 'Oil & Gas',
    iconUrl: icon14,
    href: '',
  },
  {
    title: 'FMCG (Fast-Moving Consumer Goods)',
    iconUrl: icon11,
    href: '',
  },
  {
    title: 'Information Technology (IT)',
    iconUrl: icon13,
    href: '',
  }
];
  pageTitle('Home');
  return (
    <Fragment>
<>
    <Header />
      <HeroStyle2 
        title="Powering Your Workforce, Transforming Your Business"
        subTitle="XNovaQ offers end-to-end staffing solutions that drive growth and innovation. With expertise across multiple industries, we match your business with the skilled professionals essential for success."
        bgUrl={HomeV1}
        imgUrl={homeImg}
        videoBtnText=""
        videoUrl=""
        btnText="XNovaQ Staffing Solutions"
        btnUrl="/"
        funfactList={[
          { number: '300+', title: 'Global Clients' },
          { number: '25+', title: 'Industries Served' },
          { number: '20%', title: 'Faster Time To Hire' },
          { number: '100%', title: 'Satisfaction Guarantee' },
        ]}
      />
      <Section bottomMd={125} bottomLg={50} bottomXl={85}>
        <AboutSectionStyle3
          titleUp="ABOUT US"
          title="XNovaQ - Talent Solutions for a Brighter Future"
          subTitle="XNovaQ believes the right talent is key to success. With extensive industry experience and a commitment to excellence, we connect businesses with the talent they need to thrive. Our team understands your unique needs, offers strategic staffing solutions, and supports you throughout your journey."
          imgUrl={aboutimg}
        />
      </Section>
      <Section bottomMd={125} bottomLg={50} bottomXl={60}>
        <DepartmentSectionStyle6
          sectionTitle="Staffing Solutions"
          sectionTitleUp="Our Comprehensive"
          data={departmentData}
        />
      </Section>
    
      <Section bottomMd={125} bottomLg={50} bottomXl={60}>
                <DepartmentSection
          sectionTitle="Departments"
          bgUrl={depImg}
          data={departmentData1}
        />
      </Section>
      {/* <Section>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl={depImg}
          data={departmentData3}
        />
      </Section> */}
      <Section bottomMd={125} bottomLg={50} bottomXl={85} className="cs_footer_margin_0">
        {/* <BannerSectionStyle3
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl={Banner1}
        title="Connecting Talent Globally"
        subTitle="With offices in Coimbatore, Hyderabad, and Bentonville, we support businesses in hiring the right talent across borders. Our pan-India services extend to all Tier 1 cities, delivering local expertise with a global reach."
        /> */}
         {/* <BannerSectionStyle7
          imgUrl={Banner1}
          bgUrl="/images/departments/banner_bg_3.svg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        /> */}
         <Banner
          bgUrl={bgGlobe}
          imgUrl={Banner1}
          title="Connecting Talent Globally"
          subTitle="With offices in Coimbatore, Hyderabad, and Bentonville, we support businesses in hiring the right talent across borders. Our pan-India services extend to all Tier 1 cities, delivering local expertise with a global reach."
        />
      </Section>
      <Section bottomMd={125} bottomLg={50} bottomXl={85}>
        <WhyUs
          subtitle="XNovaQ Staffing Framework"
          // sectionTitleUp=""
          // sectionTitleDown=""
          // sectionSubTitle=""
          img={staffing}
          style={'staffingFamework'}
          policies={workingProcessData}
        />
      </Section>

      <Section bottomMd={125} bottomLg={50} bottomXl={85}>
                <DepartmentSectionStyle2
          sectionTitle="Why Partners Choose XNovaQ"
          // sectionTitleUp="#1"
          data={departmentData2}
        />
      </Section>
      <Section bottomMd={125} bottomLg={50} bottomXl={85}>
      <BrandsSection data={brandData1} sectionTitle="Our Partners" />
      </Section>
      <Section bottomMd={125} bottomLg={80} bottomXl={85}>
      <AppointmentWithContactInfoSection />
      </Section>
      {/* Start FAQ Section */}
      <Section bottomMd={125} bottomLg={50} bottomXl={85}>
        <FaqSectionStyle2
          data={faqData}
          sectionTitle="Frequently Asked Questions"
          sectionTitleUp=""
        />
      </Section>
      {/* End FAQ Section */}
      
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSectionStyle2 data={brandData} /> */}
      {/* </Section> */}
      <Footer />
      <Scrollbar />
    </>
    </Fragment>
    
  );
};
export default HomeStyle2;
